<template>
  <div class="list-manage order-details">
    <section class="list-manage-table">
      <div class="table-title">
        <div></div>
        <span class="title">{{ idList.button_id == 0 ? '中期结算' : '订单完结' }}</span>
      </div>
      <section class="order-device-list">
        <div class="device-list-item" v-for="(item, index) in orderList" :key="index">
          <div class="device-list-header"><img src="~@/assets/images/temp/temp_02.png" class="avatar"
              alt="">{{ item.company_name }}</div>
          <div class="device-info-list">
            <div class="device-info-item" v-for="(item2, index) in item.children" :key="index">
              <img :src="item2.show_img" @error.once="errorLoadImg" class="pic" alt="">
              <div class="content">
                <div class="name">{{ item2.name }}</div>
                <div>
                  <div class="tag">{{ item2.type_name }}</div><span
                    class="f-orange">¥{{ item2.rental_fee }}/{{ item2.rental_type == 0 ? '日' : item2.rental_type == 1 ? '月'
                        : item2.rental_type == 2 ? '半年' : '年'
                    }}</span>
                </div>
              </div>
              <div class="tar">
                <div>租赁台数 <el-input-number v-model="item2.num" size="mini" style="width: 94px;" :min="1" :max="999"
                    label="描述文字" @change="getActualPrice" @input.native="handlerChange"></el-input-number>
                </div>
                <div class="mt6">租赁时长（{{ item2.rental_type == 0 ? '日' : item2.rental_type == 1 ? '月' : item2.rental_type == 2 ?
                    '半年' : '年'
                }}）<el-input-number v-model="item2.rental_time" @change="getActualPrice"
                    @input.native="handlerChange" size="mini" style="width: 94px;" :min="1" :max="999" label="描述文字">
                  </el-input-number>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="msg-notes">
        <div class="label">留言备注：</div>
        <el-input type="textarea" :rows="7" placeholder="请填写您的备注内容…" v-model="textarea">
        </el-input>
      </div>
      <div class="estimate-cost">
        预估费用：{{ price }}<br />实际使用费用：<small>¥</small><big>{{ actualPrice }}</big>
        <div class="btn-group"><span class="btn" @click="$router.push({ name: 'orderManage' })">取消</span><span
            class="btn btn-orange" @click="goEnd">发送申请</span></div>
      </div>
    </section>
  </div>
</template>

<script>
import { getOrderList, setOrderState } from "@/api/home.js";
import kits from '@/kits'
export default {
  data() {
    return {
      textarea: '',
      price: 0,
      actualPrice: 0,
      orderList: [],
      a1:[],
      goEndList: {
        inputs: {
          reality_order_rental_fee: []
        },

      },
      idList: {},
      a: 0
    };
  },
  created() {
    this.idList.id = this.$route.query.id * 1
    this.idList.button_id = this.$route.query.button_id * 1
    this.getOrderList()
  },
  methods: {
    async getOrderList() {
      const { data: res } = await getOrderList({ inputs: { order_id: this.idList.id }, pagination: {}, searchs: {}, compares: {} })
      console.log('res',res);
      for (let i = 0; i < res.order.length; i++) {
        for(let k = 0; k < res.order_info[i].length;k++) {
          res.order_info[i][k].show_img= kits.img.url(res.order_info[i][k].show_img)
        }
        res.order[i].children = res.order_info[i]
				res.order[i].consumer_head = kits.img.url(res.order[i].consumer_head)
        this.price += res.order[i].estimate_rental_fee
      }
      this.orderList = res.order
      // this.a1=JSON.stringify(res.order)
      console.log(this.orderList);
      this.actualPrice = this.price

    },
    async goEnd() {
      const confirm = await this.$confirm('是否确认信息无误, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(error => error)
      if (confirm !== 'confirm') {
        return this.$message.info('您已取消申请')
      }
      // if(JSON.stringify(this.orderList)=== this.a1){
      //   return this.$message.warning('没有修改,请勿提交')
      // }else{
        this.goEndList.inputs.remark = this.textarea
        /* 传进来的订单id */
        this.goEndList.inputs.big_order_id = this.idList.id
        /* 传出去的订单状态 8:订单完结申请 5:中期结算 */
        if (this.idList.button_id == 0) {
          this.goEndList.inputs.order_state = 5
        } else {
          this.goEndList.inputs.order_state = 8
        }
        /* 一层循环push进每个公司的id */
        for (let i = 0; i < this.orderList.length; i++) {
          this.goEndList.inputs.reality_order_rental_fee.push({ small_order_id: this.orderList[i].order_id, rental: [] })
          /* 二层循环push 每个公司的子对象 */
          for (let k = 0; k < this.orderList[i].children.length; k++) {
            this.goEndList.inputs.reality_order_rental_fee[i].rental.push({
              consumer_id: this.orderList[i].children[k].consumer_id,
              device_lease_id: this.orderList[i].children[k].device_lease_id,
              /* 单价类型 */
              rental_type: this.orderList[i].children[k].rental_type,
              /* 数量 */
              num: this.orderList[i].children[k].num,
              /* 天数 */
              rental_time: this.orderList[i].children[k].rental_time,
              /* order infor id */
              order_info_id: this.orderList[i].children[k].order_info_id
            })
          }
        // }
      }
      console.log(this.goEndList);
      /* 申请 */
      const res = await setOrderState(this.goEndList)
        if (res.status !== 200) {
          this.$message.error('申请失败')
        }
        this.$message.success('申请成功')
        this.goEndList = {
          inputs: {},
          reality_order_rental_fee: []
        }
        this.$router.push({ name: 'orderManage' })
    },
    getActualPrice() {
      let a = 0
      this.orderList.forEach(item => {
        item.children.forEach(item2 => {
          a += item2.rental_fee * item2.rental_time * item2.num
        })
      })
      this.actualPrice = parseInt(a)
    },
    handlerChange(e) {
      if (isNaN(e.data * 1) == true) {
        this.$message.error('只能为整数')
      }
    },
    errorLoadImg(e){
        e.currentTarget.src =require("@/assets/images/temp/temp_01.png")
      }
  }
}
</script>

<style scoped>

</style>
